<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>
          <span v-if="invoice.application === 1 || invoice.application === true">
            Application #{{ invoice.jobNumber }}
            &nbsp;|&nbsp;Application status: <span class="highlight">{{ invoice.statusWord }}</span>
          </span>
          <span v-else>
            Invoice #{{ invoice.jobNumber }}
            &nbsp;|&nbsp;Invoice status: <span class="highlight">{{ invoice.statusWord }}</span>
          </span>
          <span class="float-right">View</span>
        </h1>
        <p v-if="invoice.vatStatus === 1">
          This invoice is issued in compliance with HMRC Domestic Reverse Charging Scheme for the Construction Industry
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 pt-0 pb-0">
        <router-link
          custom v-slot="{ navigate }"
          :to="`/invoices/print-view/${invoice.id}`">
          <v-btn
            small
            @click="navigate"
            @keypress.enter="navigate"
            role="link"
            class="relative"
            style="top:-4px">
              Print View
          </v-btn>
        </router-link>
        &nbsp;
        <router-link
          custom v-slot="{ navigate }"
          :to="`/live-jobs/overview/${invoice.quotationId}`">
          <v-btn
            small
            @click="navigate"
            @keypress.enter="navigate"
            role="link"
            class="relative"
            style="top:-4px">
              Job Overview
          </v-btn>
        </router-link>
        &nbsp;
        <router-link
          custom v-slot="{ navigate }"
          :to="`/live-jobs/view/${invoice.quotationId}`">
          <v-btn
            small
            @click="navigate"
            @keypress.enter="navigate"
            role="link"
            class="relative"
            style="top:-4px">
              Job Card
          </v-btn>
        </router-link>
        &nbsp;
        <router-link
          custom v-slot="{ navigate }"
          :to="`/invoices/credit-note/${invoice.id}`">
          <v-btn
            small
            color="red"
            @click="navigate"
            @keypress.enter="navigate"
            role="link"
            class="relative"
            style="top:-4px">
              Raise Credit Note
          </v-btn>
        </router-link>
        &nbsp;
        <router-link
          custom v-slot="{ navigate }"
          :to="`/invoices/payment-history/${invoice.id}`">
          <v-btn
            small
            @click="navigate"
            @keypress.enter="navigate"
            role="link"
            class="relative"
            style="top:-4px">
              Payment
          </v-btn>
        </router-link>
        &nbsp;
        <v-btn
          v-if="changesToSave"
          small
          @click="saveInvoice"
          class="relative"
          style="top:-4px">
            Save Changes
        </v-btn>
        &nbsp;
        <v-btn
          v-if="invoice.status === 1"
          small
          @click="convertToInvoice"
          class="relative"
          style="top:-4px">
            Convert to invoice
        </v-btn>
        <v-btn
          v-if="invoice.status === 9"
          small
          @click="convertProFormaToInvoice"
          class="relative"
          style="top:-4px">
            Convert to invoice
        </v-btn>
        <v-btn
          v-if="invoice.status === 2 || invoice.status === 3"
          small
          @click="convertToApplication"
          class="relative"
          style="top:-4px">
            Convert to application
        </v-btn>
        &nbsp;
        <v-btn
          v-if="invoice.status === 2 || invoice.status === 9"
          small
          @click="finaliseInvoice()"
          class="relative"
          style="top:-4px">
            Finalise
        </v-btn>
        &nbsp;
        <v-btn
          small
          @click="exportInvoice()"
          class="relative"
          style="top:-4px">
            Export to Excel
        </v-btn>
        &nbsp;
        <v-btn
          small
          color="red"
          @click="warnDeleteInvoice = true"
          class="relative"
          style="top:-4px">
            Delete
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-6">
        <v-text-field v-model="invoice.customer_name"></v-text-field>
        <span v-if="invoice.addressOne">
          <v-text-field v-model="invoice.addressOne" label="Address line one"></v-text-field>
        </span>
        <span v-if="invoice.addressTwo">
          <v-text-field v-model="invoice.addressTwo" label="Address line two"></v-text-field>
        </span>
        <span v-if="invoice.addressThree">
          <v-text-field v-model="invoice.addressThree" label="Address line three"></v-text-field>
        </span>
        <span v-if="invoice.addressFour">
          <v-text-field v-model="invoice.addressFour" label="Address line four"></v-text-field>
        </span>
        <span v-if="invoice.addressFive">
          <v-text-field v-model="invoice.addressFive" label="Address line five"></v-text-field>
        </span>
        <span v-if="invoice.postcode">
          <v-text-field v-model="invoice.postcode" label="Postcode"></v-text-field>
        </span>
        <vue-editor
          id="additionalCodes"
          :editor-toolbar="customToolbar"
          v-model="invoice.additional_codes" />
      </v-col>
      <v-col class="col-6">
        <p>
          Document number: {{ invoice.jobNumber }}
        </p>
        <p>
          <v-text-field label="PO Number" v-model="invoice.poNumber"></v-text-field>
        </p>
        <div
          class="pb-4"
          v-if="invoice.poUri.length === 0">
          <v-btn @click="uploadPo = true" small>
            Upload Purchase Order
          </v-btn>
          <v-dialog max-width="700" v-model="uploadPo">
            <v-card>
              <app-upload-po
                :quotationId="invoice.quotationid"
                @closeUploader="completeUpload"></app-upload-po>
              <v-card-actions>
                <v-btn text @click="uploadPo = false">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <div
          class="pb-4"
          v-else>
          <a
            :href="`https://riggotts.s3.eu-west-2.amazonaws.com/original/${invoice.poUri}`"
            target="_blank">
            <v-btn small>View Purchase Order</v-btn>
          </a>
        </div>
        <p>
          Date of last DRS: {{ invoice.lastDrsDate | tinyDate }}
        </p>
        <p>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="computedDateFormattedDatefns"
                clearable
                label="Invoice date / tax point"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="invoice.date = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="invoice.date"
              @change="menu = false"
            ></v-date-picker>
          </v-menu>
        </p>
        <p>
          Account reference: {{ invoice.accountReference }}
        </p>
        <p>
          <v-textarea
            v-model="invoice.notes"
            label="Internal notes (will not show on print view)"
            rows="3"></v-textarea>
        </p>
        <p v-if="invoice.vatStatus === 3">This invoice is 0 VAT rate (new build).</p>
        <v-btn small @click="updateVatStatus = true">Update VAT Status</v-btn>
      </v-col>
    </v-row>
    <v-row class="pt-5">
      <v-col class="col-12">
        <v-simple-table dense fixed-header height="230px">
          <thead>
            <tr>
              <th>Nominal</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>4000 Sale of goods</td>
              <td>
                <v-text-field v-model="nominals.one" type="number" step="0.01" prefix="£"></v-text-field>
              </td>
            </tr>
            <tr>
              <td>4001 Thermo</td>
              <td>
                <v-text-field v-model="nominals.two" type="number" step="0.01" prefix="£"></v-text-field>
              </td>
            </tr>
            <tr>
              <td>4002 Late payment charges</td>
              <td>
                <v-text-field v-model="nominals.ten" type="number" step="0.01" prefix="£"></v-text-field>
              </td>
            </tr>
            <tr>
              <td>4003 Paint</td>
              <td>
                <v-text-field v-model="nominals.three" type="number" step="0.01" prefix="£"></v-text-field>
              </td>
            </tr>
            <tr>
              <td>4004 Jet Washing</td>
              <td>
                <v-text-field v-model="nominals.eleven" type="number" step="0.01" prefix="£"></v-text-field>
              </td>
            </tr>
            <tr>
              <td>4008 Anti-skid</td>
              <td>
                <v-text-field v-model="nominals.four" type="number" step="0.01" prefix="£"></v-text-field>
              </td>
            </tr>
            <tr>
              <td>4015 Hydroblasting</td>
              <td>
                <v-text-field v-model="nominals.five" type="number" step="0.01" prefix="£"></v-text-field>
              </td>
            </tr>
            <tr>
              <td>4020 Hydroblasting (Sub-contracted)</td>
              <td>
                <v-text-field v-model="nominals.six" type="number" step="0.01" prefix="£"></v-text-field>
              </td>
            </tr>
            <tr>
              <td>4025 Shotblasting</td>
              <td>
                <v-text-field v-model="nominals.seven" type="number" step="0.01" prefix="£"></v-text-field>
              </td>
            </tr>
            <tr>
              <td>4030 Traffic Management</td>
              <td>
                <v-text-field v-model="nominals.eight" type="number" step="0.01" prefix="£"></v-text-field>
              </td>
            </tr>
            <tr>
              <td>4101 Rent from Mast</td>
              <td>
                <v-text-field v-model="nominals.twelve" type="number" step="0.01" prefix="£"></v-text-field>
              </td>
            </tr>
            <tr>
              <td>4200 Sales of Assets</td>
              <td>
                <v-text-field v-model="nominals.thirteen" type="number" step="0.01" prefix="£"></v-text-field>
              </td>
            </tr>
            <tr>
              <td>4904 Rental Income Warings Unit 2</td>
              <td>
                <v-text-field v-model="nominals.fourteen" type="number" step="0.01" prefix="£"></v-text-field>
              </td>
            </tr>
            <tr>
              <td>4907 Rental Income Warings Unit 5</td>
              <td>
                <v-text-field v-model="nominals.fifteen" type="number" step="0.01" prefix="£"></v-text-field>
              </td>
            </tr>
            <tr>
              <td>6050 Sub-contractor</td>
              <td>
                <v-text-field v-model="nominals.nine" type="number" step="0.01" prefix="£"></v-text-field>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row class="pt-10">
      <v-col class="col-12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Description</th>
                <th class="text-left">Material</th>
                <th class="text-left">Colour</th>
                <th class="text-left">Quantity</th>
                <th class="text-left">Units</th>
                <th class="text-left">Rate</th>
                <th class="text-left">Value</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(line, index) in invoice.lines" :key="index">
                <td>
                  <v-text-field v-model="line.description"></v-text-field>
                </td>
                <td>
                  <v-autocomplete
                    :items="materials"
                    v-model="line.material_id"></v-autocomplete>
                </td>
                <td>
                  <v-select
                    v-model="line.colour_id"
                    :items="colours"></v-select>
                </td>
                <td>
                  <v-text-field
                    v-model="line.quantity"
                    type="number"
                    min="0"
                    step="0.01"></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="line.units"></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="line.pounds"
                    type="number"
                    step="0.01"></v-text-field>
                </td>
                <td>
                  {{ (line.quantity * line.pounds) | currency }}
                </td>
                <td>
                  <v-icon
                    color="red"
                    @click="confirmDeleteLine(index)"
                  >
                    mdi-trash-can-outline
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-btn
          @click="addInvoiceLine"
          x-small
          class="ml-5">Add line</v-btn>
      </v-col>
    </v-row>
    <v-row class="pt-10">
      <v-col class="col-6">
        <vue-editor
          id="descriptionNotes"
          :editor-toolbar="customToolbar"
          v-model="invoice.description_notes" />
      </v-col>
    </v-row>
    <v-row class="pt-10">
      <v-col class="col-6">
        <p>
          <v-text-field v-model="invoice.siteLocation" label="Delivery location"></v-text-field>
        </p>
        <p>
          <label>Work Description</label>
          <vue-editor
            id="workDescription"
            :editor-toolbar="customToolbar"
            v-model="invoice.workDescription" />
        </p>
        <p>
          <v-select
            v-model="invoice.contact_id"
            label="Contact"
            :items="contacts"
            item-text="full_name"
            item-value="id"></v-select>
        </p>
      </v-col>
      <v-col class="col-6 pr-20">
        <p>
          Sub-total:
          <span class="float-right">
            {{ subTotal | priceInPounds | currency }}
          </span>
        </p>
        <p>
          <span v-if="invoice.status !== 1">
            <span v-if="invoice.vatStatus === 2">
              VAT @20%:
            </span>
          </span>
          <span v-if="invoice.status !== 1" class="float-right">
            <span v-if="invoice.vatStatus === 2">
              {{ vat | priceInPounds | currency }}
            </span>
          </span>
        </p>
        <p>
          Total:
          <span class="float-right">
            {{ (subTotal + vat) | priceInPounds | currency }}
          </span>
        </p>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showMessage"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-2"
            text
            @click="showMessage = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showConfirmInvoice"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          Are you sure?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="showConfirmInvoice = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green"
            text
            @click="sendFinaliseInvoice"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showInvoiceFinalised"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          The invoice has been posted to Sage.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <router-link :to="`/invoices/view/${invoice.id}`">
            <v-btn
              color="orange darken-2"
              text
            >
              Close
            </v-btn>
          </router-link>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="warnDeleteInvoice"
      max-width="400"
    >
      <v-card>
        <v-card-text class="pt-4">
          <span v-if="invoice.status === 1">
            You are about to delete this Application
          </span>
          <span v-else>
            You are about to delete this Draft invoice
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="deleteInvoice" x-small color="red">Confirm</v-btn>
          &nbsp;
          <v-btn @click="warnDeleteInvoice = false" x-small>Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="warnDeleteLine"
      max-width="400"
    >
      <v-card>
        <v-card-text class="pt-4">
          You are about to delete this invoice line
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="deleteLine" x-small color="red">Confirm</v-btn>
          &nbsp;
          <v-btn @click="warnDeleteLine = false" x-small>Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="updateVatStatus"
      max-width="800"
    >
      <v-card>
        <v-card-text class="pt-4">
          Update the VAT status of this invoices
          <v-select
            v-model="vatStatus.domVatZero"
            :items="[{ value: 0, text: 'No'}, { value: 1, text: 'Yes'}]"
            label="Is Riggots supply to be within the CIS scheme?"
          ></v-select>
          <v-select
            v-model="vatStatus.domVatOne"
            :items="[{ value: 0, text: 'No'}, { value: 1, text: 'Yes'}]"
            label="Is the customer VAT registered?"
          ></v-select>
          <v-select
            v-model="vatStatus.domVatTwo"
            :items="[{ value: 0, text: 'No'}, { value: 1, text: 'Yes'}]"
            label="Is the customer a contractor in the chain?"
          ></v-select>
          <v-select
            v-model="newBuildVat"
            :items="[{ value: 0, text: 'No'}, { value: 1, text: 'Yes'}]"
            label="Should this invoice be zero rated for VAT (new build)? "
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="saveVatStatus" x-small>Save</v-btn>
          &nbsp;
          <v-btn @click="updateVatStatus = false" x-small>Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { format, parseISO } from 'date-fns';
import { VueEditor } from "vue2-editor";
import axios from '../../axios';
import UploadPo from '@/components/Quotations/UploadPO.vue';

export default {
  name: 'InvoicesView',
  components: {
    VueEditor,
    appUploadPo: UploadPo,
  },
  data() {
    return {
      changesToSave: false,
      changeNumber: 0,
      invoice: {
        lines: [],
        date: '',
      },
      contacts: [],
      menu: false,
      startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      subTotal: 0,
      vat: 0,
      nominalsTotal: 0,
      message: '',
      showMessage: false,
      showConfirmInvoice: false,
      showInvoiceFinalised: false,
      nominals: {
        one: 0, two: 0, three: 0, four: 0, five: 0, six: 0, seven: 0, eight: 0, nine: 0, ten: 0, eleven: 0, twelve: 0, thirteen: 0, fourteen: 0, fifteen: 0,
      },
      colours: [],
      materials: [],
      customToolbar: [
        ["bold", "italic", "underline"],
      ],
      updateVatStatus: false,
      vatStatus: {},
      newBuildVat: 0,
      warnDeleteInvoice: false,
      warnDeleteLine: false,
      lineToDelete: {},
      uploadPo: false,
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    computedDateFormattedDatefns () {
      return this.invoice.date ? format(parseISO(this.invoice.date), 'EEEE, MMMM do yyyy') : ''
    },
    userid() {
      return this.$store.state.userid;
    }
  },
  watch: {
    startDate () {
      this.invoice.date = this.formatDate(this.startDate);
    },
    invoice: {
      deep: true,
      handler() {
        let subtotal = 0;
        for ( let i = 0; this.invoice.lines.length > i; i += 1) {
          const lineTotal = this.invoice.lines[i].quantity * this.invoice.lines[i].pounds;
          subtotal += lineTotal;
        }
        this.subTotal = Math.round(subtotal * 100);

        if(this.invoice.status !== 1) {
          if(this.invoice.vatStatus === 2) {
            this.vat = ((this.subTotal * 1.2) - this.subTotal);
          }
        }
        if (this.changeNumber > 1) {
          this.changesToSave = true;
        }
        this.changeNumber += 2;
      },
    },
    nominals: {
      deep: true,
      handler() {
        if (this.changeNumber > 1) {
          this.changesToSave = true;
        }
        this.changeNumber += 1;
        this.nominalsTotal = (
          parseFloat(this.nominals.one, 10) +
          parseFloat(this.nominals.two, 10) +
          parseFloat(this.nominals.three, 10) +
          parseFloat(this.nominals.four, 10) +
          parseFloat(this.nominals.five, 10) +
          parseFloat(this.nominals.six, 10) +
          parseFloat(this.nominals.seven, 10) +
          parseFloat(this.nominals.eight, 10) +
          parseFloat(this.nominals.nine, 10) +
          parseFloat(this.nominals.ten, 10) +
          parseFloat(this.nominals.eleven, 10) +
          parseFloat(this.nominals.twelve, 10) +
          parseFloat(this.nominals.thirteen, 10) +
          parseFloat(this.nominals.fourteen, 10) +
          parseFloat(this.nominals.fifteen, 10));
      },
    },
  },
  methods: {
    saveVatStatus() {
      const postData = {};
      postData.invoiceId = this.invoice.id;
      postData.vatStatus = this.vatStatus;
      postData.newBuildVat = this.newBuildVat;
      axios.post(`/invoices/updateVatStatus.json?token=${this.token}`, postData)  
        .then((response) => {
          if (response.data.result === true) {
            this.updateVatStatus = false;
            this.invoice = {};
            this.getInvoice();
          } else {
            this.message = 'The VAT status was not updated. Please try again.';
            this.showMessage = true;
          }
        });
    },
    confirmDeleteLine(index) {
      this.warnDeleteLine = true;
      this.lineToDelete = index;
    },
    deleteLine() {
      this.warnDeleteLine = false;
      const postData = {};
      postData.line = this.invoice.lines[this.lineToDelete];
      axios.post(`/invoiceLines/deleteLine.json?token=${this.token}`, postData)  
        .then((response) => {
          if (response.data.deleted === true) {
            this.invoice.lines.splice(this.lineToDelete, 1);
          } else {
            this.message = 'The invoice line could not be deleted. Please try again.';
            this.showMessage = true;
          }
        });
    },
    deleteInvoice() {
      this.warnDeleteInvoice = false;
      const postData = {};
      postData.invoice = this.invoice;
      axios.post(`/invoices/deleteDraft.json?token=${this.token}`, postData)  
        .then(() => {
          this.$router.push('/invoices/index')
        });
    },
    exportInvoice() {
      axios.get(`/spreadsheets/invoice/${this.invoice.id}.json?token=${this.token}`, {responseType:'blob'})
        .then((response) => {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `invoice_${this.invoice.jobNumber}.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
    },
    addInvoiceLine() {
      const line = {
        description: '',
        quantity: 0,
        pounds: 0,
        units: '',
        material_id: 0,
        colour_id: 0,
      };
      this.invoice.lines.push(line);
    },
    formatDate (date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
    finaliseInvoice() {
      if (this.subTotal === Math.round(this.nominalsTotal * 100)) {
        this.showConfirmInvoice = true;
      } else {
        this.message = 'The total allocated to nominals must match the subtotal';
        this.showMessage = true;
      }
    },
    sendFinaliseInvoice() {
      this.showConfirmInvoice = false;
      const postData = {};
      postData.invoice = this.invoice;
      postData.nominals = this.nominals;
      postData.userid = this.userid;
      axios.post(`/invoices/finaliseInvoice.json?token=${this.token}`, postData)  
        .then((response) => {
          this.invoice = response.data;
          this.showInvoiceFinalised = true;
        });
    },
    convertToInvoice() {
      axios.post(`/invoices/convertApplication.json?token=${this.token}`, this.invoice)
        .then((response) => {
          this.invoice = response.data;
        });
    },
    convertProFormaToInvoice() {
      axios.post(`/invoices/convertPFToInvoice.json?token=${this.token}`, this.invoice)
        .then((response) => {
          this.invoice = response.data;
        });
    },
    convertToApplication() {
      axios.post(`/invoices/convertToApplication.json?token=${this.token}`, this.invoice)
        .then((response) => {
          this.invoice = response.data;
        });
    },
    saveInvoice() {
      const postData = {};
      postData.invoice = this.invoice;
      postData.nominals = this.nominals;
      postData.userid = this.userid;
      axios.post(`/invoices/saveUpdates.json?token=${this.token}`, postData)
        .then((response) => {
          this.invoice = response.data.invoice;
          this.message = response.data.message;
          this.showMessage = true;
        });
    },
    getColours() {
      axios.get(`/colours/getAll.json?token=${this.token}`)
        .then((response) => {
          this.colours = response.data;
          this.getMaterials();
        });
    },
    getMaterials() {
      axios.get(`/materials/getAll.json?token=${this.token}`)
        .then((response) => {
          this.materials = response.data;
        });
    },
    getInvoice() {
      axios.get(`/invoices/getSingle/${this.$route.params.id}.json?token=${this.token}`)
      .then((response) => {
        this.invoice = response.data;
        this.subTotal = response.data.value;
        this.vat = response.data.vat;
        this.contacts = response.data.contacts;
        this.updateNominals(response.data.nominals);
      });
    },
    updateNominals(nominals) {
      for(let i = 0; nominals.length > i; i +=1) {
        if (nominals[i].nominal_code === '4000') {
          this.nominals.one = (nominals[i].amount / 100);
        }
        if (nominals[i].nominal_code === '4001') {
          this.nominals.two = (nominals[i].amount / 100);
        }
        if (nominals[i].nominal_code === '4003') {
          this.nominals.three = (nominals[i].amount / 100);
        }
        if (nominals[i].nominal_code === '4008') {
          this.nominals.four = (nominals[i].amount / 100);
        }
        if (nominals[i].nominal_code === '4015') {
          this.nominals.five = (nominals[i].amount / 100);
        }
        if (nominals[i].nominal_code === '4020') {
          this.nominals.six = (nominals[i].amount / 100);
        }
        if (nominals[i].nominal_code === '4025') {
          this.nominals.seven = (nominals[i].amount / 100);
        }
        if (nominals[i].nominal_code === '4030') {
          this.nominals.eight = (nominals[i].amount / 100);
        }
        if (nominals[i].nominal_code === '6050') {
          this.nominals.nine = (nominals[i].amount / 100);
        }
        if (nominals[i].nominal_code === '4002') {
          this.nominals.ten = (nominals[i].amount / 100);
        }
        if (nominals[i].nominal_code === '4004') {
          this.nominals.eleven = (nominals[i].amount / 100);
        }
        if (nominals[i].nominal_code === '4101') {
          this.nominals.twelve = (nominals[i].amount / 100);
        }
        if (nominals[i].nominal_code === '4200') {
          this.nominals.thirteen = (nominals[i].amount / 100);
        }
        if (nominals[i].nominal_code === '4904') {
          this.nominals.fourteen = (nominals[i].amount / 100);
        }
        if (nominals[i].nominal_code === '4907') {
          this.nominals.fifteen = (nominals[i].amount / 100);
        }
      }
    },
  },
  mounted() {
    this.getColours();
    this.getInvoice();
  },
};
</script>